import React from "react";

import Button from "@material-ui/core/Button";
import clsx from "clsx";

import useAuthenticationMiducStore from "../../store/authenticationMiduc";

const LogoutButton = () => {
  const { isLoggedIn, setIsLoggedIn, setToken } = useAuthenticationMiducStore();

  const handleOnClick = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setToken(undefined);
    window.location.reload();
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={clsx("w-3/4", "mx-auto", "absolute bottom-4 right-0 left-0")}>
      <Button color="secondary" variant="contained" fullWidth onClick={handleOnClick}>
        Salir
      </Button>
    </div>
  );
};

export default LogoutButton;
