import create, { SetState } from "zustand";

import namespaced from "~/src/utils/debug";

const debug = namespaced("context/authentication");

type AuthenticationState = {
  token?: string;
  expiration?: string;
  authId?: string;
  isLoggedIn: boolean;
  setToken: (value?: string) => void;
  setIsLoggedIn: (value: boolean) => void;
  setExpiration: (value: string) => void;
  setAuthId: (value?: string) => void;
};

const useAuthenticationMiducStore = create(
  (set: SetState<AuthenticationState>): AuthenticationState => ({
    token: undefined,
    isLoggedIn: false,
    expiration: undefined,
    authId: undefined,
    setToken: (token?: string) => {
      set({ token });
      if (!token) {
        localStorage.removeItem("google-access-token");
      }
      debug("token state changed\n", token);
    },
    setIsLoggedIn: (isLoggedIn: boolean) => {
      set({ isLoggedIn });
      debug("isLoggedIn state changed\n", isLoggedIn);
    },
    setExpiration: (expiration: string) => {
      set({ expiration });
    },
    setAuthId: (authId?: string) => {
      set({ authId });
    },
  }),
);

export default useAuthenticationMiducStore;
