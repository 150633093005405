const isProd = process.env.NODE_ENV === "production";

function namespaced(namespace: string) {
  return function debug(msg: any, ...rest: any[]) {
    if (!isProd) {
      // eslint-disable-next-line no-console
      console.log(`${namespace}:`, msg, ...rest);
    }
  };
}

export default namespaced;
