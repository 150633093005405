import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { Link } from "gatsby";
import { ImPriceTags } from "react-icons/im";
import { MdDashboard, MdImage } from "react-icons/md";

import { Props } from "../../types/common";
import LogoutButton from "./LogoutButton";

type ItemParams = {
  icon: JSX.Element;
  name: string;
  path: string;
  active?: boolean;
};

type ItemsLayoutProps = {
  values: Array<ItemParams>;
};

function Item({ icon, name, path, active }: ItemParams) {
  return (
    <Link
      to={path}
      className={clsx("w-full py-2 flex items-center cursor-pointer hover:bg-gray-200", active && "bg-gray-100")}
    >
      <div className="px-4 text-blue-400">{icon}</div>
      <div className="capitalize">{name}</div>
    </Link>
  );
}

function ItemsLayout({ values }: ItemsLayoutProps): JSX.Element {
  return (
    <div>
      {values.map(
        (value, index): JSX.Element => (
          <Item key={index} {...value}></Item>
        ),
      )}
    </div>
  );
}

function Layout({ children }: Props) {
  const defaultMenuOptions: Array<ItemParams> = [
    {
      name: "dashboard",
      path: "/dashboard",
      icon: <MdDashboard />,
    },
    {
      name: "cupones",
      path: "/coupons",
      icon: <ImPriceTags />,
    },
    {
      name: "promos",
      path: "/promos",
      icon: <MdImage />,
    },
  ];
  const [menuOptions, setMenuOptions] = useState(defaultMenuOptions);

  useEffect(() => {
    const page = window?.location?.pathname?.split("/")[1];
    const menuIndex = defaultMenuOptions.map((x) => x.path).indexOf("/" + page);
    const options = [...defaultMenuOptions];
    /*
     * the first element is the active element if there is no valid index
     */
    options[menuIndex < 0 ? 0 : menuIndex].active = true;
    setMenuOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-screen flex">
      <div className="flex-none w-52 relative">
        <div className="w-full text-center py-2 font-semibold capitalize">
          <img
            className="w-4/5 py-7 h-3/4 object-contain mx-auto my-0"
            src="https://provider-logos-2.s3.us-east-1.amazonaws.com/marketplace.png"
          />
        </div>
        <ItemsLayout values={menuOptions} />
        <LogoutButton />
      </div>
      <div className="flex-auto">
        <div className="max-h-screen min-w-full overflow-auto">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
